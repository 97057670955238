import Vue from 'vue'
import Vuex from 'vuex'

import usuario from './modules/usuario'
import pagina from './modules/pagina'

Vue.use(Vuex)

const debug = process.env.VUE_APP_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    usuario,
    pagina
  },
  actions: {
    async "socket_prevn" ({ commit, dispatch }, data) {
      if(data.commit != null){
        commit(data.commit, data.data);
      }
      if(data.dispatch != null){
        dispatch(data.dispatch, data.data);
      }
    }
  },
  strict: debug
})