import Vue from 'vue'
import './plugins/bootstrap-vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueSimpleAlert from "vue-simple-alert"
import VueSidebarMenu from 'vue-sidebar-menu'
import vSelect from "vue-select";
import VueGoodTablePlugin from 'vue-good-table';
import VueExcelXlsx from "vue-excel-xlsx";
import VueAxios from 'vue-axios'
import axios from 'axios'
import Slider from '@vueform/slider/dist/slider.vue2.js'
import draggable from 'vuedraggable'
import VueFileAgent from 'vue-file-agent';
import Datepicker from 'vuejs-datepicker';

import store from './store/index.js';
import router from './router/index.js'
import App from './app/App.vue';
import Main from './app/Main.vue';
import NoHabilitado from './components/NoHabilitado';
import TopBar from './components/TopBar';
import Ibox from './components/Ibox';
import FormGroup from './components/FormGroup';
import CheckBox from './components/CheckBox';
import Loading from './components/Loading.vue'
import DataTable from './components/DataTable.vue'
import ListGroup from './components/ListGroup.vue'
import OrganizacionArbol from './components/OrganizacionArbol.vue'
import FileAgent from './components/FileAgent.vue'
import { Timeline } from 'vue-visjs'


import "vue-select/dist/vue-select.css";

Vue.config.productionTip = false

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueSimpleAlert)
Vue.use(VueSidebarMenu)
Vue.use(VueGoodTablePlugin);
Vue.use(VueExcelXlsx);
Vue.use(VueFileAgent);
//Vue.use(VueFileAgentStyles);
Vue.use(VueAxios, axios);
Vue.use(draggable);

Vue.component('NoHabilitado', NoHabilitado);
Vue.component('TopBar', TopBar);
Vue.component('Ibox', Ibox);
Vue.component('FormGroup', FormGroup);
Vue.component('Main', Main);
Vue.component("v-select", vSelect);
Vue.component("Datepicker", Datepicker);
Vue.component("CheckBox", CheckBox);
Vue.component("Loading", Loading);
Vue.component("draggable",draggable);
Vue.component("Slider",Slider);
Vue.component("DataTable",DataTable);
Vue.component("OrganizacionArbol",OrganizacionArbol);
Vue.component("ListGroup",ListGroup);
Vue.component("FileAgent",FileAgent);
Vue.component('timeline', Timeline);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
