<template>
  <div>
    <Ibox style="margin-top:3px" v-if="mostrarSeleccionarCliente()">
      <FormGroup
        cols="6"
        label="Cliente"
        :is_loading="loading">
        <v-select 
          v-model="cliente.id_cliente"
          @input="setCliente"
          :clearable=false
          label="nombre"
          :options="clientes" 
          :reduce="option=>option.id_cliente">
        </v-select>
      </FormGroup>
      <FormGroup
        v-if="loading_cliente"
        cols="6"
        label="Cargando cliente"
        :is_loading="loading">
        <b-skeleton type="input" width="100%"></b-skeleton>
      </FormGroup>
      <FormGroup
        v-else
        cols="6"
        label="Cliente actual"
        :is_loading="loading">
        <input v-if="loading_cliente == false" type="text" class="form-control" v-model="$store.state.usuario.nombre_cliente" disabled>
      </FormGroup>
    </Ibox>
  </div>
</template>

<script>

import { globales, clienteService, userService } from "@/services";
import { utils } from "@/helpers";

import Vue from 'vue'
import VueSocketIO from 'vue-socket.io'
import SocketIO from 'socket.io-client'
import { config } from '../services/config';
import store from '../store/index.js';

export default {
  data() {
    return {
      clientes: [],
      cliente: {
        id_cliente: this.$store.state.usuario.id_cliente,
        nombre_cliente: this.$store.state.usuario.nombre_cliente
      },
      loading:true,
      loading_cliente:false,
    }
  },
  mounted(){
    if(this.mostrarSeleccionarCliente()){
      this.getClientes();
    }
  },
  methods : {
    mostrarSeleccionarCliente(){
      return utils.inJsonArray(globales.perfil.admin, 'id_perfil', this.$store.state.usuario.perfiles);
    },
    setCliente(id_cliente){
      this.loading_cliente = true;
      userService.getUsuario({
        id_cliente: id_cliente,
        id_usuario: this.$store.state.usuario.id_usuario,
        t: this.$store.state.usuario.t
      }).then(data => {
        this.$store.commit('usuario/putUsuario',data.data);
        this.loading_cliente = false;

        //desconecto socket
        Vue.prototype.$socket.disconnect();

        //conecto socket nuevamente con info de cliente nuevo
        Vue.use(new VueSocketIO({
          debug: true,
          connection: SocketIO(config.socketUrl,{
            query:{
              d: utils.je64({
                c: store.state.usuario.id_cliente,
                u: store.state.usuario.id_usuario
              })
            }
          }),
          vuex: {
            store,
            actionPrefix: 'socket_',
            mutationPrefix: 'socket_'
          },
        }));


        this.$emit("clientecargado");
      });
    },
    getClientes(){
      clienteService.getCliente({
        id_cliente:'-1'
      }).then(data => {
        this.loading = false;
        if(data.status == "success"){
          this.clientes = data.data;
        }
      });
    },
  }
}
</script>