import { api } from './api';

export const sucursalService = {
    getSucursal,
    postSucursal,
    putSucursal,
    getSucursalUsuarios,
    putSucursalUsuario,
    getSucursalAreas,
    postSucursalArea,
    putSucursalArea,
    getSucursalAreaUsuarios,
    putSucursalAreaUsuario
};

function putSucursalAreaUsuario(data) {
  return api.post(
    api.tx.putSucursalAreaUsuario, 
    data
  ).then(data => {
    return data;
  });
}

function getSucursalAreaUsuarios(data) {
  return api.post(
    api.tx.getSucursalAreaUsuarios, 
    data
  ).then(data => {
    return data;
  });
}

function postSucursal(data) {
  return api.post(
    api.tx.postSucursal, 
    data
  ).then(data => {
    return data;
  });
}

function putSucursal(data) {
  return api.post(
    api.tx.putSucursal, 
    data
  ).then(data => {
    return data;
  });
}

function getSucursal(data) {
  return api.post(
    api.tx.getSucursal, 
    data
  ).then(data => {
    return data;
  });
}

function getSucursalUsuarios(data) {
  return api.post(
    api.tx.getSucursalUsuarios, 
    data
  ).then(data => {
    return data;
  });
}


function putSucursalUsuario(data) {
  return api.post(
    api.tx.putSucursalUsuario, 
    data
  ).then(data => {
    return data;
  });
}

function getSucursalAreas(data) {
  return api.post(
    api.tx.getSucursalAreas, 
    data
  ).then(data => {
    return data;
  });
}

function postSucursalArea(data) {
  return api.post(
    api.tx.postSucursalArea, 
    data
  ).then(data => {
    return data;
  });
}

function putSucursalArea(data) {
  return api.post(
    api.tx.putSucursalArea, 
    data
  ).then(data => {
    return data;
  });
}
