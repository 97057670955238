import { api } from './api';

export const inspeccionService = {
  getInspecciones,
  getInspeccionTipoInspeccionArbol,
  postInspeccionTipoInspeccion,
  putInspeccionTipoInspeccion,
  getInspeccionMotivoReasignacion,
  postInspeccionMotivoReasignacion,
  putInspeccionMotivoReasignacion,
  getInspeccionMotivoCancelacion,
  postInspeccionMotivoCancelacion,
  putInspeccionMotivoCancelacion,
  getInspeccionBitacora,
  postInspeccionBitacora,
  getCorreoInspeccion,
  getInspeccionEjecutores,
  putInspeccion,
  agregarevidenciafoto,
  agregarevidenciaarchivo,
  getInspeccionKpi,
  getInspeccionIndicadoresSucursal,
  getInspeccionIndicadoresTipo,
  getInspeccionGraficoTorta,
  getInspeccionGraficoHistorial,
  getInspeccionHistoricoEjecutores,
  getInspeccionWidgetInspectorEjecutor,
  getInspeccionWidgetPorTipo,
  getInspeccionWidgetPorSubTipo
};

function getInspeccionWidgetPorSubTipo(data) {
  return api.post(
    api.tx.getInspeccionWidgetPorSubTipo, 
    data
  ).then(data => {
    return data;
  });
}

function getInspeccionWidgetPorTipo(data) {
  return api.post(
    api.tx.getInspeccionWidgetPorTipo, 
    data
  ).then(data => {
    return data;
  });
}

function getInspeccionWidgetInspectorEjecutor(data) {
  return api.post(
    api.tx.getInspeccionWidgetInspectorEjecutor, 
    data
  ).then(data => {
    return data;
  });
}

function getInspeccionHistoricoEjecutores(data) {
  return api.post(
    api.tx.getInspeccionHistoricoEjecutores, 
    data
  ).then(data => {
    return data;
  });
}

function getInspeccionGraficoHistorial(data) {
  return api.post(
    api.tx.getInspeccionGraficoHistorial, 
    data
  ).then(data => {
    return data;
  });
}

function getInspeccionGraficoTorta(data) {
  return api.post(
    api.tx.getInspeccionGraficoTorta, 
    data
  ).then(data => {
    return data;
  });
}

function getInspeccionIndicadoresTipo(data) {
  return api.post(
    api.tx.getInspeccionIndicadoresTipo, 
    data
  ).then(data => {
    return data;
  });
}

function getInspeccionIndicadoresSucursal(data) {
  return api.post(
    api.tx.getInspeccionIndicadoresSucursal, 
    data
  ).then(data => {
    return data;
  });
}

function getInspeccionKpi(data) {
  return api.post(
    api.tx.getInspeccionKpi, 
    data
  ).then(data => {
    return data;
  });
}

function agregarevidenciafoto(data) {
  return api.post(
    api.tx.agregarevidenciafoto, 
    data
  ).then(data => {
    return data;
  });
}

function agregarevidenciaarchivo(data) {
  return api.post(
    api.tx.agregarevidenciaarchivo, 
    data
  ).then(data => {
    return data;
  });
}

function putInspeccion(data) {
  return api.post(
    api.tx.putInspeccion, 
    data
  ).then(data => {
    return data;
  });
}

function getInspeccionEjecutores(data) {
  return api.post(
    api.tx.getInspeccionEjecutores, 
    data
  ).then(data => {
    return data;
  });
}

function getCorreoInspeccion(data) {
  return api.post(
    api.tx.getCorreoInspeccion, 
    data
  ).then(data => {
    return data;
  });
}

function postInspeccionBitacora(data) {
  return api.post(
    api.tx.postInspeccionBitacora, 
    data
  ).then(data => {
    return data;
  });
}

function getInspeccionBitacora(data) {
  return api.post(
    api.tx.getInspeccionBitacora, 
    data
  ).then(data => {
    return data;
  });
}

function getInspecciones(data) {
  return api.post(
    api.tx.getInspecciones, 
    data
  ).then(data => {
    return data;
  });
}

function getInspeccionTipoInspeccionArbol(data) {
  return api.post(
    api.tx.getInspeccionTipoInspeccionArbol, 
    data
  ).then(data => {
    return data;
  });
}

function postInspeccionTipoInspeccion(data) {
  return api.post(
    api.tx.postInspeccionTipoInspeccion, 
    data
  ).then(data => {
    return data;
  });
}

function putInspeccionTipoInspeccion(data) {
  return api.post(
    api.tx.putInspeccionTipoInspeccion, 
    data
  ).then(data => {
    return data;
  });
}

function getInspeccionMotivoReasignacion(data) {
  return api.post(
    api.tx.getInspeccionMotivoReasignacion, 
    data
  ).then(data => {
    return data;
  });
}

function postInspeccionMotivoReasignacion(data) {
  return api.post(
    api.tx.postInspeccionMotivoReasignacion, 
    data
  ).then(data => {
    return data;
  });
}

function putInspeccionMotivoReasignacion(data) {
  return api.post(
    api.tx.putInspeccionMotivoReasignacion, 
    data
  ).then(data => {
    return data;
  });
}

function getInspeccionMotivoCancelacion(data) {
  return api.post(
    api.tx.getInspeccionMotivoCancelacion, 
    data
  ).then(data => {
    return data;
  });
}

function postInspeccionMotivoCancelacion(data) {
  return api.post(
    api.tx.postInspeccionMotivoCancelacion, 
    data
  ).then(data => {
    return data;
  });
}

function putInspeccionMotivoCancelacion(data) {
  return api.post(
    api.tx.putInspeccionMotivoCancelacion, 
    data
  ).then(data => {
    return data;
  });
}