import { api } from './api';

export const listaCorreoService = {
  getListasCorreo,
  getListaCorreo,
  postListaCorreo,
  putListaCorreo
};

function getListaCorreo(data){
  return api.post(
    api.tx.getListaCorreo, 
    data
  ).then(data => {
    return data;
  });
}

function getListasCorreo(data){
  return api.post(
    api.tx.getListasCorreo, 
    data
  ).then(data => {
    return data;
  });
}

function postListaCorreo(data){
  return api.post(
    api.tx.postListaCorreo, 
    data
  ).then(data => {
    return data;
  });
}

function putListaCorreo(data){
  return api.post(
    api.tx.putListaCorreo, 
    data
  ).then(data => {
    return data;
  });
}