// initial state
const state = {
  titulo:'Prevn'
};

// getters
const getters = {}

// actions
const actions = {}

// mutations
const mutations = {
  setTitulo (state, titulo) {
    state.titulo = titulo || 'Prevn';
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}