<template>
  <div class="switch">
    <div class="onoffswitch" @click="onChange">
      <input
          class='onoffswitch-checkbox'
          type='checkbox'
          v-model="value">
      <label class='onoffswitch-label'>
        <span class="onoffswitch-inner"></span>
        <span class="onoffswitch-switch"></span>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: "CheckBox",
  props: ['value'],
  methods:{
    onChange(){
      let active = !this.value;
      this.$emit("update-value", active);
    }
  }
}
</script>

<style scoped>

</style>