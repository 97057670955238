import { api } from './api';

export const programaService = {
  getProgramas,
  getProgramaActividades,
  getProgramaUsuarios,
  getProgramaChecklist,
  getProgramaReporteDetallado,
  getProgramaReporteKpiUsuarios,
  postPrograma,
  putPrograma,
  postProgramaActividad,
  putProgramaActividad,
  putProgramaUsuario,
  deleteProgramaActividad,
  getProgramaUsuariosAsociados,
  getProgramaTimeline,
  getProgramaIndicadores,
  getProgramaTimelineCalendarizacion,
  postProgramaCalendarizacion,
  putProgramaCalendarizacion,
  deleteProgramaCalendarizacion,
  copyProgramaCalendarizacion,
  deleteProgramaCalendarizacionMasivo,
  getDeleteProgramaCalendarizacionMasivo,
  getCopyProgramaCalendarizacion,
  getProgramaWidgetTablaAvance,
  getProgramaWidgetTablaAvanceFecha,
  getProgramaWidgetBarraAvanceFecha,
  getProgramaWidgetBarraFechaUsuarios
};

function getProgramaWidgetBarraFechaUsuarios(data) {
  return api.post(
    api.tx.getProgramaWidgetBarraFechaUsuarios, 
    data
  ).then(data => {
    return data;
  });
}

function getProgramaWidgetBarraAvanceFecha(data) {
  return api.post(
    api.tx.getProgramaWidgetBarraAvanceFecha, 
    data
  ).then(data => {
    return data;
  });
}

function getProgramaWidgetTablaAvance(data) {
  return api.post(
    api.tx.getProgramaWidgetTablaAvance, 
    data
  ).then(data => {
    return data;
  });
}

function getProgramaWidgetTablaAvanceFecha(data) {
  return api.post(
    api.tx.getProgramaWidgetTablaAvanceFecha, 
    data
  ).then(data => {
    return data;
  });
}

function getCopyProgramaCalendarizacion(data) {
  return api.post(
    api.tx.getCopyProgramaCalendarizacion, 
    data
  ).then(data => {
    return data;
  });
}

function getDeleteProgramaCalendarizacionMasivo(data) {
  return api.post(
    api.tx.getDeleteProgramaCalendarizacionMasivo, 
    data
  ).then(data => {
    return data;
  });
}

function copyProgramaCalendarizacion(data) {
  return api.post(
    api.tx.copyProgramaCalendarizacion, 
    data
  ).then(data => {
    return data;
  });
}

function deleteProgramaCalendarizacionMasivo(data) {
  return api.post(
    api.tx.deleteProgramaCalendarizacionMasivo, 
    data
  ).then(data => {
    return data;
  });
}

function postProgramaCalendarizacion(data) {
  return api.post(
    api.tx.postProgramaCalendarizacion, 
    data
  ).then(data => {
    return data;
  });
}

function putProgramaCalendarizacion(data) {
  return api.post(
    api.tx.putProgramaCalendarizacion, 
    data
  ).then(data => {
    return data;
  });
}

function deleteProgramaCalendarizacion(data) {
  return api.post(
    api.tx.deleteProgramaCalendarizacion, 
    data
  ).then(data => {
    return data;
  });
}

function getProgramaTimelineCalendarizacion(data) {
  return api.post(
    api.tx.getProgramaTimelineCalendarizacion, 
    data
  ).then(data => {
    return data;
  });
}

function getProgramaIndicadores(data) {
  return api.post(
    api.tx.getProgramaIndicadores, 
    data
  ).then(data => {
    return data;
  });
}

function getProgramaTimeline(data) {
  return api.post(
    api.tx.getProgramaTimeline, 
    data
  ).then(data => {
    return data;
  });
}

function getProgramaUsuariosAsociados(data) {
  return api.post(
    api.tx.getProgramaUsuariosAsociados, 
    data
  ).then(data => {
    return data;
  });
}

function getProgramas(data) {
  return api.post(
    api.tx.getProgramas, 
    data
  ).then(data => {
    return data;
  });
}

function getProgramaActividades(data) {
  return api.post(
    api.tx.getProgramaActividades, 
    data
  ).then(data => {
    return data;
  });
}

function getProgramaUsuarios(data) {
  return api.post(
    api.tx.getProgramaUsuarios, 
    data
  ).then(data => {
    return data;
  });
}

function getProgramaChecklist(data) {
  return api.post(
    api.tx.getProgramaChecklist, 
    data
  ).then(data => {
    return data;
  });
}

function getProgramaReporteDetallado(data) {
  return api.post(
    api.tx.getProgramaReporteDetallado, 
    data
  ).then(data => {
    return data;
  });
}

function postPrograma(data) {
  return api.post(
    api.tx.postPrograma, 
    data
  ).then(data => {
    return data;
  });
}

function putPrograma(data) {
  return api.post(
    api.tx.putPrograma, 
    data
  ).then(data => {
    return data;
  });
}

function postProgramaActividad(data) {
  return api.post(
    api.tx.postProgramaActividad, 
    data
  ).then(data => {
    return data;
  });
}

function putProgramaActividad(data) {
  return api.post(
    api.tx.putProgramaActividad, 
    data
  ).then(data => {
    return data;
  });
}

function putProgramaUsuario(data) {
  return api.post(
    api.tx.putProgramaUsuario, 
    data
  ).then(data => {
    return data;
  });
}

function getProgramaReporteKpiUsuarios(data) {
  return api.post(
    api.tx.getProgramaReporteKpiUsuarios, 
    data
  ).then(data => {
    return data;
  });
}

function deleteProgramaActividad(data) {
  return api.post(
    api.tx.deleteProgramaActividad, 
    data
  ).then(data => {
    return data;
  });
}