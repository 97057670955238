<template>
  <div>
    <div v-if="this.$store.state.usuario.habilitado == 'N'">
      <NoHabilitado/>
    </div>
    <div v-else-if="this.$store.state.usuario.id_usuario != null" id="div_app" :class="[{'collapsed' : collapsed}, {'onmobile' : isOnMobile}]">
      <TopBar/>
      <b-col >
        <router-view v-if="this.$store.state.usuario.id_cliente != -1"></router-view>
        <SeleccionCliente v-else></SeleccionCliente>
      </b-col>
      <sidebar-menu
        :width="'300px'"
        :collapsed="collapsed" 
        :menu="menu"
        :showOneChild="true"
        @toggle-collapse="onToggleCollapse"
        :theme="'white-theme'">
        <span slot="toggle-icon" v-if="collapsed">
          <b-icon scale="1.5" icon="arrow-right"/>
        </span>
        <span slot="toggle-icon" v-else>
          <b-icon scale="1.5" icon="arrow-left"/>
        </span>
        <div slot="header" v-if="!collapsed">
          <b-col style="padding-top:11px; padding-bottom:10px; background-color:white; border-bottom: 4px solid #e7eaec">
            <div>
                <img width="85" src="../assets/logo_menu.png">
            </div>
          </b-col>
        </div>
      </sidebar-menu>
    </div>
    <div v-else>
      <MainLoading/>
    </div>
  </div>
</template>
<script>

import { prevnService, userService } from "@/services";
import MainLoading from '../components/MainLoading.vue'
import SeleccionCliente from '../components/SeleccionCliente.vue'

import 'vue-sidebar-menu/dist/vue-sidebar-menu.css'
import '@fortawesome/fontawesome-free/css/all.css'

export default {
  components:{
    MainLoading,
    SeleccionCliente
  },
  mounted () {
    this.onResize();
    window.addEventListener('resize', this.onResize);
    this.getData();
  },
  data() {
    return {
      loading: true,
      collapsed: false,
      isOnMobile: false,
      menu: [{'title':'Cargando menu'}]
    }
  },
  methods: {
    getData(){
      this.loading = true;
      prevnService.getMenu({
        id_usuario:userService.getUsuarioStorage().id_usuario
      }).then(data => {
        if(data.status == "success"){
          this.menu = data.data;
        }
        this.loading = false;
      });
    },
    onToggleCollapse (collapsed) {
      console.log(collapsed)
      this.collapsed = collapsed
    },
    onResize () {
      if (window.innerWidth <= 767) {
        this.isOnMobile = true
        this.collapsed = true
      } else {
        this.isOnMobile = false
        this.collapsed = false
      }
    }
  },
}
</script>

<style>
  @import '../assets/style.css';

  i {
    color:rgb(243, 161, 0)
  }

  #div_app {
    padding-left: 300px;
    transition: 0.3s ease;
  }
  #div_app.collapsed {
    padding-left: 50px;
  }
  #div_app.onmobile {
    padding-left: 50px;
  }

  .v-sidebar-menu.vsm_white-theme .vsm--link_level-1.vsm--link_exact-active .vsm--icon, .v-sidebar-menu.vsm_white-theme .vsm--link_level-1.vsm--link_active .vsm--icon {
    color: white !important;
  }

  .v-sidebar-menu.vsm_white-theme .vsm--link_level-1 .vsm--icon {
    background-color: white !important;
  }
  
  .v-sidebar-menu.vsm_white-theme .vsm--link_active .vsm--icon {
    background-color: #f3a100 !important;
  }

  .v-sidebar-menu.vsm_white-theme.vsm_expanded .vsm--item_open .vsm--link_level-1 {
    background-color: #f3a100 !important;
  }

  .vsm--link_active {
    background-color: #f3a100 !important;
    color:white !important;
  }

  .vsm--link_active .vsm--icon {
    background-color: #f3a100 !important;
    color:white;
  }

  .v-sidebar-menu.vsm_white-theme.vsm_expanded .vsm--item_open .vsm--link_level-1 .vsm--icon {
    background-color: #f3a100 !important;
    color:white;
  }

</style>
