import { userService, globales } from "@/services";

// initial state
const state = {
  id_cliente: userService.getUsuarioStorage() != null ? userService.getUsuarioStorage().id_cliente : null,
  nombre_cliente: userService.getUsuarioStorage() != null ? userService.getUsuarioStorage().nombre_cliente : null,
  id_usuario: userService.getUsuarioStorage() != null ? userService.getUsuarioStorage().id_usuario : null,
  nombre_usuario: userService.getUsuarioStorage() != null ? userService.getUsuarioStorage().nombre_usuario : null,
  habilitado: userService.getUsuarioStorage() != null ? userService.getUsuarioStorage().habilitado : null,
  permiso: userService.getUsuarioStorage() != null ? userService.getUsuarioStorage().permiso : null,
  perfiles: userService.getUsuarioStorage() != null ? userService.getUsuarioStorage().perfiles : null,
  sucursales: userService.getUsuarioStorage() != null ? userService.getUsuarioStorage().sucursales : null,
  t: userService.getUsuarioStorage() != null ? userService.getUsuarioStorage().t : null,
  menu: userService.getUsuarioStorage() != null ? userService.getUsuarioStorage().menu : null,
  configuracion: userService.getUsuarioStorage() != null ? userService.getUsuarioStorage().configuracion : null,
  categorias: userService.getUsuarioStorage() != null ? userService.getUsuarioStorage().categorias : null,
  areas: userService.getUsuarioStorage() != null ? userService.getUsuarioStorage().areas : null,
  tipo_inspeccion: userService.getUsuarioStorage() != null ? userService.getUsuarioStorage().tipo_inspeccion : null,
  usa_organizacion: userService.getUsuarioStorage() != null ? userService.getUsuarioStorage().usa_organizacion : null,
  organizacion: userService.getUsuarioStorage() != null ? userService.getUsuarioStorage().organizacion : null,
  formularios: userService.getUsuarioStorage() != null ? userService.getUsuarioStorage().formularios : null,
  programas: userService.getUsuarioStorage() != null ? userService.getUsuarioStorage().programas : null,
  selecciones: userService.getUsuarioStorage() != null ? userService.getUsuarioStorage().selecciones : null,
  un_correo_formulario: userService.getUsuarioStorage() != null ? userService.getUsuarioStorage().un_correo_formulario : null,
  listas_correo: userService.getUsuarioStorage() != null ? userService.getUsuarioStorage().listas_correo : null,
};

// getters
const getters = {
  tienePerfil : (state) => id_perfil => {
    return state.perfiles.some(function(element){
      return element.id_perfil == id_perfil;
    });
  },
  getProgramasFiltro : (state) => objeto => {
    let array = Array.from(state.programas);
    if(objeto){
      //reviso que el objeto agregado no repita un id
      if(!state.programas.find((element) => element.id_programa == objeto.id_programa)){
        array.unshift(objeto);
      }
    }
    return array;
  },
  getSucursalesFiltro : (state) => objeto => {
    let array = Array.from(state.sucursales);
    if(objeto){
      //reviso que el objeto agregado no repita un id
      if(!state.sucursales.find((element) => element.id_sucursal == objeto.id_sucursal)){
        array.unshift(objeto);
      }
    }

    //filtro id_sucursal repetidos
    const idsSucursalUnicos = {};
    const sucursalesFiltradas = array.filter((sucursal) => {
      if (!idsSucursalUnicos[sucursal.id_sucursal]) {
        idsSucursalUnicos[sucursal.id_sucursal] = true;
        return true;
      }
      return false;
    });

    return sucursalesFiltradas;
  },
  //obtengo las sucursales filtradas por perfil
  getSucursalesIdPerfil : (state) => id_perfil => {
    let array = Array.from(state.sucursales);
    if(id_perfil == globales.perfil.admin || id_perfil == globales.perfil.admin_cliente){
      return array;
    }else{
      return array.filter((element) => element.id_perfil == id_perfil);
    }
  },
  getCategoriasFiltro : (state) => objeto => {
    let array = Array.from(state.categorias);
    if(objeto){
      //reviso que el objeto agregado no repita un id
      if(!state.categorias.find((element) => element.id_categoria == objeto.id_categoria)){
        array.unshift(objeto);
      }
    }
    return array;
  },
  getOrganizacionFiltro : (state) => objeto => {
    let array = [];

    let arbol = createTree(state.organizacion);

    state.organizacion.forEach(function(element){
      let targetNode = arbol[element.id_nodo];

      array.push({
        id_nodo:getNodeAndDescendantsIds(targetNode).join(','),
        id_padre:element.id_padre,
        nombre_nodo: ' - '.repeat(parseInt(element.nivel)+1) +  element.nombre_nodo
      });
    });

    if(objeto){
      //si no existen nodos que representen todos los nodos del usuario, agrego "todo"
      if(array.find((element) => objeto.id_nodo == element.id_nodo) == undefined){
        array.unshift(objeto);
      }
      
    }
    return array;
  },
  getAreasFiltro : (state) => objeto => {
    let array = Array.from(state.areas);
    if(objeto){
      //reviso que el objeto agregado no repita un id
      if(!state.areas.find((element) => element.id_area == objeto.id_area)){
        let arr_sucursales = objeto.id_sucursal.split(",");
        array = state.areas.filter(function(element){
          return arr_sucursales.includes(element.id_sucursal);
        });
        array.unshift(objeto);
      }
    }
    return array;
  },
  getFormulariosFiltro : (state) => objeto => {
    let array = Array.from(state.formularios);
    
    if(objeto){
      if(objeto.id_categoria != null){
        let arr_categorias = objeto.id_categoria.split(",");
        array = state.formularios.filter(function(element){
          return arr_categorias.includes(element.id_categoria);
        });
      }
      array.unshift(objeto);
    }
    return array;
  },
  getTipoInspeccionFiltro : (state) => objeto => {
    let array = Array.from(state.tipo_inspeccion);
    if(objeto){
      array.unshift(objeto);
    }
    return array;
  },
}

// actions
const actions = {
  sincronizar : ({commit})  => {
    userService.getUsuario({
      'id_cliente':state.id_cliente,
      'id_usuario':state.id_usuario,
      't':state.t
    }).then(data => {
      commit('putUsuario',data.data);
    });
  },
  putSeleccion : ({commit}, seleccion) => {
    commit('setSeleccion', seleccion);
  },
  postSeleccion : ({commit}, seleccion) => {
    commit('addSeleccion', seleccion);
  },
  setUsuario: ({commit}, usuario) => {
    commit('putUsuario', usuario);
  }
}

// mutations
const mutations = {
  setSeleccion(state, seleccion){
    state.selecciones.forEach((element) => {
      if(element.id_seleccion == seleccion.id_seleccion){
        element.opciones = JSON.stringify(seleccion.opciones);
      }
    });
  },
  addSeleccion(state, seleccion){
    seleccion.opciones = JSON.stringify(seleccion.opciones);
    state.selecciones.push(seleccion);
  },
  addSucursal (state, sucursal) {
    state.sucursales.unshift(sucursal);
  },
  putUsuario (state, usuario) {
    state.id_cliente = usuario.id_cliente;
    state.nombre_cliente = usuario.nombre_cliente;
    state.id_usuario = usuario.id_usuario;
    state.nombre_usuario = usuario.nombre_usuario;
    state.permiso = usuario.permiso;
    state.habilitado = usuario.habilitado;
    state.perfiles = usuario.perfiles;
    state.t = usuario.t;
    state.sucursales = usuario.sucursales == null ? state.sucursales : usuario.sucursales;
    state.categorias = usuario.categorias == null ? state.categorias : usuario.categorias;
    state.tipo_inspeccion = usuario.tipo_inspeccion == null ? state.tipo_inspeccion : usuario.tipo_inspeccion;
    state.areas = usuario.areas == null ? state.areas : usuario.areas;
    state.menu = usuario.menu == null ? state.menu: usuario.menu;
    state.organizacion = usuario.organizacion == null ? state.organizacion: usuario.organizacion;
    state.usa_organizacion = usuario.usa_organizacion;
    state.formularios = usuario.formularios == null ? state.formularios: usuario.formularios;
    state.programas = usuario.programas == null ? state.programas: usuario.programas;
    state.selecciones = usuario.selecciones == null ? state.selecciones: usuario.selecciones;
    state.un_correo_formulario = usuario.un_correo_formulario == null ? state.un_correo_formulario : usuario.un_correo_formulario;
    state.listas_correo = usuario.listas_correo == null ? state.listas_correo : usuario.listas_correo;
  },
  deleteUsuario (state) {
    state.id_cliente = null;
    state.nombre_cliente = null;
    state.id_usuario = null;
    state.nombre_usuario = null;
    state.permiso = null;
    state.habilitado = null;
    state.perfiles = null;
    state.sucursales = null;
    state.menu = null;
    state.t = null;
    state.configuracion = null;
    state.categorias = null;
    state.areas = null;
    state.tipo_inspeccion = null;
    state.usa_organizacion = null;
    state.organizacion = null;
    state.formularios = null;
    state.programas = null;
    state.un_correo_formulario = null;
    state.selecciones = null;
    state.listas_correo = null;
  }
}

function createTree(nodes) {
  const tree = {};

  nodes.forEach(node => {
    tree[node.id_nodo] = { ...node, children: [] };
  });

  nodes.forEach(node => {
    if (node.id_padre !== "0") {
      const parentNode = tree[node.id_padre];
      if (parentNode) {
        parentNode.children.push(tree[node.id_nodo]);
      }
    }
  });

  return tree;
}

function getNodeAndDescendantsIds(node, ids = []) {
  ids.push(node.id_nodo);

  node.children.forEach(child => {
    getNodeAndDescendantsIds(child, ids);
  });

  return ids;
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}