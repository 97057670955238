import { api } from './api';

export const menuService = {
    getMenuFuncionalidades,
    getMenuFuncionalidadPerfil,
    postMenuFuncionalidad,
    putMenuFuncionalidadPerfil,
    putMenuFuncionalidad,
};

function getMenuFuncionalidades(data) {
  return api.post(
    api.tx.getMenuFuncionalidades, 
    data
  ).then(data => {
    return data;
  });
}

function getMenuFuncionalidadPerfil(data) {
  return api.post(
    api.tx.getMenuFuncionalidadPerfil, 
    data
  ).then(data => {
    return data;
  });
}

function postMenuFuncionalidad(data) {
  return api.post(
    api.tx.postMenuFuncionalidad, 
    data
  ).then(data => {
    return data;
  });
}

function putMenuFuncionalidadPerfil(data) {
  return api.post(
    api.tx.putMenuFuncionalidadPerfil, 
    data
  ).then(data => {
    return data;
  });
}

function putMenuFuncionalidad(data) {
  return api.post(
    api.tx.putMenuFuncionalidad, 
    data
  ).then(data => {
    return data;
  });
}