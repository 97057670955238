import Vue from 'vue'
import VueRouter from 'vue-router'
import VueSocketIO from 'vue-socket.io'
import SocketIO from 'socket.io-client';

import { config } from '../services/config';
import { userService } from '../services/user.services'
import { utils } from '@/helpers'
import store from '../store/index.js';
import { elements } from 'chart.js';

Vue.use(VueRouter)

const routes = [
  {
      path:'/main/',
      name:'main',
      component: () => import('../app/Main'),
      children:[
          {
            path: '/home',
            name: 'home',
            meta: {
              title: 'Dashboard'
            },
            component: () => import('../screens/home/ScreenHome.vue'),
          },
          {
            path: '/log/log/',
            name: 'log/log',
            meta: {
              title: 'Auditoria'
            },
            component: () => import('../screens/log/Log.vue'),
          },
          {
            path: '/menu/configurar/',
            name: 'menu/configurar',
            meta: {
              title: 'Menu / Configuración'
            },
            component: () => import('../screens/menu/Configuracion.vue'),
          },
          {
            path: '/cliente/clientes/',
            name: 'cliente/clientes',
            meta: {
              title: 'Configuración / Clientes'
            },
            component: () => import('../screens/cliente/Clientes.vue'),
          },
          {
            path: '/cliente/configuracion/:cliente',
            name: 'cliente/configuracion',
            meta: {
              title: 'Clientes / Configuración'
            },
            component: () => import('../screens/cliente/Configuracion.vue'),
          },

          {
            path: '/cliente/logo/:cliente',
            name: 'cliente/logo',
            meta: {
              title: 'Clientes / Configuración / Logo'
            },
            component: () => import('../screens/cliente/Logo.vue'),
          },
          {
            path: '/sistema/configurar/',
            name: 'sistema/configurar',
            meta: {
              title: 'Sistema / Configuración'
            },
            component: () => import('../screens/sistema/Configuracion.vue'),
          },
          {
            path: '/documento/configurar/',
            name: 'documento/configurar',
            meta: {
              title: 'Documentos / Configuración'
            },
            component: () => import('../screens/documento/Configuracion.vue'),
          },
          {
            path: '/documento/agregar/',
            name: 'documento/agregar',
            meta: {
              title: 'Documentos / Configuración / Agregar documento'
            },
            component: () => import('../screens/documento/Agregar.vue'),
          },
          {
            path: '/documento/editar/:documento',
            name: 'documento/editar',
            meta: {
              title: 'Documentos / Configuración / Editar documento'
            },
            component: () => import('../screens/documento/Editar.vue'),
          },
          {
            path: '/documento/enviar/',
            name: 'documento/enviar',
            meta: {
              title: 'Documentos / Envio y firma de documentos'
            },
            component: () => import('../screens/documento/FirmaryEnviar.vue'),
          },
          {
            path: '/ayuda/tutoriales/',
            name: 'ayuda/tutoriales',
            meta: {
              title: 'Ayuda / Tutoriales'
            },
            component: () => import('../screens/ayuda/Tutoriales.vue'),
          },
          {
            path: '/lista_correo/configurar/',
            name: 'lista_correo/configurar',
            meta: {
              title: 'Configuración / Listas de correo'
            },
            component: () => import('../screens/lista_correo/Configuracion.vue')
          },
          {
            path: '/lista_correo/agregar/',
            name: 'lista_correo/agregar',
            meta: {
              title: 'Configuración / Listas de correo / Agregar'
            },
            component: () => import('../screens/lista_correo/Agregar.vue')
          },
          {
            path: '/lista_correo/editar/:lista_correo',
            name: 'lista_correo/editar',
            meta: {
              title: 'Configuración / Listas de correo / Editar'
            },
            component: () => import('../screens/lista_correo/Editar.vue')
          },
          {
            path: '/lista_correo/ver/:lista_correo',
            name: 'lista_correo/ver',
            meta: {
              title: 'Configuración / Listas de correo / Ver'
            },
            component: () => import('../screens/lista_correo/Ver.vue')
          },
          {
            path: '/organizacion/configurar/',
            name: 'organizacion/configurar',
            meta: {
              title: 'Configuración / Organización'
            },
            component: () => import('../screens/organizacion/Configuracion.vue')
          },
          {
            path: '/estadistica/ingreso_datos',
            name: 'estadistica/ingreso_datos',
            meta: {
              title: 'Estadisticas prevención / Ingreso de datos'
            },
            component: () => import('../screens/estadistica/IngresoDatos.vue')
          },
          {
            path: '/estadistica/reportes',
            name: 'estadistica/reportes',
            meta: {
              title: 'Estadisticas prevención / Reportes'
            },
            component: () => import('../screens/estadistica/Reportes.vue')
          },
          {
            path: '/estadistica/tasas',
            name: 'estadistica/tasas',
            meta: {
              title: 'Estadisticas prevención / Tasas'
            },
            component: () => import('../screens/estadistica/Tasas.vue')
          },
          {
            path: '/programa/historial_formularios/',
            name: 'programa/historial_formularios',
            meta: {
              title: 'Programa / Formularios realizados'
            },
            component: () => import('../screens/programa/HistorialFormularios.vue')
          },
          {
            path: '/programa/reporte/:filtro',
            name: 'programa/reporte',
            meta: {
              title: 'Programa / Reporte programa'
            },
            component: () => import('../screens/programa/Reporte.vue')
          },
          {
            path: '/programa/reporte_detallado/:filtro',
            name: 'programa/reporte_detallado',
            meta: {
              title: 'Programa / Reporte programa detallado'
            },
            component: () => import('../screens/programa/ReporteDetallado.vue')
          },
          {
            path: '/programa/calendarizacion',
            name: 'programa/calendarizacion',
            meta: {
              title: 'Programa / Calendarizacion'
            },
            component: () => import('../screens/programa/Calendarizacion.vue')
          },
          {
            path: '/programa/kpi_usuarios/',
            name: 'programa/kpi_usuarios',
            meta: {
              title: 'Programa / Reporte KPI usuarios'
            },
            component: () => import('../screens/programa/ReporteKpiUsuarios.vue')
          },
          {
            path: '/programa/configuracion/',
            name: 'programa/configuracion',
            meta: {
              title: 'Configuración / Programa'
            },
            component: () => import('../screens/programa/Configuracion.vue')
          },
          {
            path: '/programa/agregar/',
            name: 'programa/agregar',
            meta: {
              title: 'Configuración / Programa / Agregar'
            },
            component: () => import('../screens/programa/Agregar.vue')
          },
          {
            path: '/programa/editar/:programa',
            name: 'programa/editar',
            meta: {
              title: 'Programa / Editar'
            },
            component: () => import('../screens/programa/Editar.vue')
          },
          {
            path: '/programa/actividad/:programa',
            name: 'programa/actividad',
            meta: {
              title: 'Programa / Actividades'
            },
            component: () => import('../screens/programa/actividad/Configuracion.vue')
          },
          {
            path: '/programa/actividad/agregar/:programa',
            name: 'programa/actividad/agregar',
            meta: {
              title: 'Configuración / Programa / Actividad / Agregar'
            },
            component: () => import('../screens/programa/actividad/Agregar.vue')
          },
          {
            path: '/programa/actividad/editar/:actividad',
            name: 'programa/actividad/editar',
            meta: {
              title: 'Configuración / Programa / Actividad / Editar'
            },
            component: () => import('../screens/programa/actividad/Editar.vue')
          },
          {
            path: '/programa/usuarios/:programa',
            name: 'programa/usuarios',
            meta: {
              title: 'Configuración / Programa / Usuarios'
            },
            component: () => import('../screens/programa/Usuarios.vue')
          },
          {
            path: '/indicadores/inspeccion/',
            name: 'indicadores/inspeccion',
            meta: {
              title: 'Indicadores / Inspecciones'
            },
            component: () => import('../screens/indicadores/Inspecciones.vue')
          },
          {
            path: '/indicadores/formulario/',
            name: 'indicadores/formulario',
            meta: {
              title: 'Indicadores / Formularios'
            },
            component: () => import('../screens/indicadores/Formularios.vue')
          },
          {
            path: '/indicadores/tarea/',
            name: 'indicadores/tarea',
            meta: {
              title: 'Indicadores / Tareas'
            },
            component: () => import('../screens/indicadores/Tareas.vue')
          },
          {
            path: '/formulario/formulario/editar/:formulario',
            name: 'formulario/formulario/editar',
            meta: {
              title: 'Configuración / Formularios / Editar'
            },
            component: () => import('../screens/formulario/formulario/Editar.vue')
          },
          {
            path: '/formulario/formulario/copiar/:formulario',
            name: 'formulario/formulario/copiar',
            meta: {
              title: 'Configuración / Formularios / Copiar'
            },
            component: () => import('../screens/formulario/formulario/Copiar.vue')
          },
          {
            path: '/formulario/listado_copia/:categoria',
            name: 'formulario/listado_copia',
            meta: {
              title: 'Configuración / Formularios / Formularios para copiar'
            },
            component: () => import('../screens/formulario/configuracion/ListadoCopia.vue')
          },
          {
            path: '/formulario/formulario/agregar/:categoria',
            name: 'formulario/formulario/agregar',
            meta: {
              title: 'Configuración / Formularios / Agregar'
            },
            component: () => import('../screens/formulario/formulario/Agregar.vue')
          },
          {
            path: '/formulario/configurar/',
            name: 'formulario/configurar',
            meta: {
              title: 'Configuración / Formularios'
            },
            component: () => import('../screens/formulario/Configuracion.vue')
          },
          {
            path: '/formulario/categoria/agregar/',
            name: 'formulario/categoria/agregar',
            meta: {
              title: 'Configuración / Formularios /  Agregar categoría'
            },
            component: () => import('../screens/formulario/categoria/Agregar.vue')
          },
          {
            path: '/formulario/categoria/usuarios/:categoria',
            name: 'formulario/categoria/usuarios',
            meta: {
              title: 'Configuración / Formularios / Categoria / Usuarios'
            },
            component: () => import('../screens/formulario/categoria/Usuarios.vue')
          },
          {
            path: '/formulario/formulario/usuarios/',
            name: 'formulario/formulario/usuarios',
            meta: {
              title: 'Configuración / Formularios / Formulario / Usuarios'
            },
            component: () => import('../screens/formulario/formulario/Usuarios.vue')
          },
          {
            path: '/formulario/categoria/editar/:categoria',
            name: 'formulario/categoria/editar',
            meta: {
              title: 'Configuración / Formularios /  Editar categoría'
            },
            component: () => import('../screens/formulario/categoria/Editar.vue')
          },
          {
            path: '/formulario/historial/:tipo',
            name: 'formulario/historial',
            meta: {
              title: 'Formularios / Historial'
            },
            component: () => import('../screens/formulario/Historial.vue')
          },
          {
            path: '/formulario/reporte/configurar/',
            name: 'formulario/reporte/configurar',
            meta: {
              title: 'Configuración / Formularios / Reportes personalizados'
            },
            component: () => import('../screens/formulario/reporte/Configuracion.vue')
          },
          {
            path: '/formulario/reporte/agregar/',
            name: 'formulario/reporte/agregar',
            meta: {
              title: 'Configuración / Formularios / Reportes personalizados / Agregar'
            },
            component: () => import('../screens/formulario/reporte/Agregar.vue')
          },
          {
            path: '/formulario/reporte/historial/',
            name: 'formulario/reporte/historia',
            meta: {
              title: 'Formularios / Reportes personalizados'
            },
            component: () => import('../screens/formulario/reporte/Historial.vue')
          },
          {
            path: '/formulario/reporte/editar/:reporte',
            name: 'formulario/reporte/editar',
            meta: {
              title: 'Configuración / Formularios / Reportes personalizados / Editar'
            },
            component: () => import('../screens/formulario/reporte/Editar.vue')
          },
          {
            path: '/inspeccion/historial/:tipo',
            name: 'inspeccion/historial',
            meta: {
              title: 'Inspecciones / Historial'
            },
            component: () => import('../screens/inspeccion/Historial.vue')
          },
          {
              path: '/inspeccion/configurar/',
              name: 'inspeccion/configurar',
              meta: {
                title: 'Inspección / Configuración'
              },
              component: () => import('../screens/inspeccion/configuracion/Configuracion.vue')
          },
          {
              path: '/inspeccion/configuracion/tipo_inspeccion/agregar/:padre',
              name: 'inspeccion/configuracion/tipo_inspeccion/agregar',
              meta: {
                title: 'Inspección / Configuración / Tipo inspección / Agregar'
              },
              component: () => import('../screens/inspeccion/configuracion/tipo_inspeccion/Agregar.vue')
          },
          {
              path: '/inspeccion/configuracion/tipo_inspeccion/editar/:tipo_inspeccion',
              name: 'inspeccion/configuracion/tipo_inspeccion/editar',
              meta: {
                title: 'Inspección / Configuración / Tipo inspección / Editar'
              },
              component: () => import('../screens/inspeccion/configuracion/tipo_inspeccion/Editar.vue')
          },
          {
              path: '/tarea/historial/:tipo',
              name: 'tarea/historial',
              meta: {
                title: 'Tareas / Historial'
              },
              component: () => import('../screens/tarea/Historial.vue')
          },
          {
            path: '/sucursal/sucursales',
            name: 'sucursal/sucursales',
            meta: {
              title: 'Configuración / Sucursales'
            },
            component: () => import('../screens/sucursal/Sucursales.vue')
          },
          {
            path: '/sucursal/agregar',
            name: 'sucursal/agregar',
            meta: {
              title: 'Configuración / Sucursales / Agregar'
            },
            component: () => import('../screens/sucursal/Agregar.vue')
          },
          {
            path: '/sucursal/editar/:sucursal',
            name: 'sucursal/editar',
            meta: {
              title: 'Configuración / Sucursales / Editar'
            },
            component: () => import('../screens/sucursal/Editar.vue')
          },
          {
            path: '/sucursal/areas/:sucursal',
            name: 'sucursal/areas',
            meta: {
              title: 'Configuración / Sucursales / Areas'
            },
            component: () => import('../screens/sucursal/area/Areas.vue')
          },
          {
            path: '/sucursal/area/agregar/:sucursal',
            name: 'sucursal/area/agregar',
            meta: {
              title: 'Configuración / Sucursales / Areas / Agregar'
            },
            component: () => import('../screens/sucursal/area/Agregar.vue')
          },
          {
            path: '/sucursal/area/editar/:area',
            name: 'sucursal/area/editar',
            meta: {
              title: 'Configuración / Sucursales / Areas / Editar'
            },
            component: () => import('../screens/sucursal/area/Editar.vue')
          },
          {
            path: '/sucursal/area/ejecutores/:area',
            name: 'sucursal/area/ejecutores',
            meta: {
              title: 'Configuración / Sucursales / Areas / Ejecutores'
            },
            component: () => import('../screens/sucursal/area/Ejecutores.vue')
          },
          {
            path: '/sucursal/usuarios/:sucursal',
            name: 'sucursal/usuarios',
            meta: {
              title: 'Configuración / Sucursales / Usuarios'
            },
            component: () => import('../screens/sucursal/Usuarios.vue')
          },
          {
              path: '/cliente/editar/:cliente',
              name: 'cliente/editar',
              meta: {
                title: 'Configuración / Cliente'
              },
              component: () => import('../screens/cliente/Editar.vue')
          },
          {
              path: '/cliente/agregar',
              name: 'cliente/agregar',
              meta: {
                title: 'Agregar cliente'
              },
              component: () => import('../screens/cliente/Agregar.vue')
          },
          {
              path: '/usuario/editar/:usuario',
              name: 'usuario/editar',
              meta: {
                title: 'Configuración / Usuarios / Editar'
              },
              props: true,
              component: () => import('../screens/usuario/Editar.vue')
          },
          {
              path: '/usuario/editartrabajador/:usuario',
              name: 'usuario/editartrabajador',
              meta: {
                title: 'Configuración / Usuarios / Editar trabajador'
              },
              props: true,
              component: () => import('../screens/usuario/EditarTrabajador.vue')
          },
          {
              path: '/usuario/usuarios',
              name: 'usuario/usuarios',
              meta: {
                title: 'Configuración / Usuarios'
              },
              props: true,
              component: () => import('../screens/usuario/Usuarios.vue')
          },
          {
              path: '/usuario/usuariostrabajador',
              name: 'usuario/usuariostrabajador',
              meta: {
                title: 'Configuración / Usuarios perfil trabajador'
              },
              props: true,
              component: () => import('../screens/usuario/UsuariosTrabajador.vue')
          },
          {
              path: '/usuario/historial/:usuario',
              name: 'usuario/historial',
              meta: {
                title: 'Configuración / Usuarios / Historial'
              },
              props: true,
              component: () => import('../screens/usuario/Historial.vue')
          },
          {
              path: '/usuario/informacion/:usuario',
              name: 'usuario/informacion',
              meta: {
                title: 'Configuración / Usuarios / Información'
              },
              props: true,
              component: () => import('../screens/usuario/Informacion.vue')
          },
          {
              path: '/usuario/agregar',
              name: 'usuario/agregar',
              meta: {
                title: 'Configuración / Usuario / Agregar'
              },
              props: true,
              component: () => import('../screens/usuario/Agregar.vue')
          },
          {
              path: '/usuario/agregartrabajador',
              name: 'usuario/agregartrabajador',
              meta: {
                title: 'Configuración / Usuario / Agregar trabajador'
              },
              props: true,
              component: () => import('../screens/usuario/AgregarTrabajador.vue')
          },
          {
              path: '/menu/agregar',
              name: 'menu/agregar',
              props: true,
              meta: {
                  title: 'Menu / Funcionalidad / Agregar'
              },
              component: () => import('../screens/menu/components/Agregar.vue')
          },
          {
              path: '/menu/editar/:funcionalidad',
              name: 'menu/editar',
              props: true,
              meta: {
                  title: 'Menu / Funcionalidad / Editar'
              },
              component: () => import('../screens/menu/components/Editar.vue')
          },
          {
              path: '/menu/funcionalidad/perfil/:funcionalidad',
              name: 'menu/funcionalidad/perfil',
              props: true,
              meta: {
                  title: 'Menu / Funcionalidad / Perfiles'
              },
              component: () => import('../screens/menu/components/FuncionalidadPerfil.vue')
          }
      ]
  },
  {
      path: '/logout',
      name: 'logout',
      component: () => import('../screens/login/ScreenLogin.vue')
  },
  {
      path: '/recuperarPass',
      name: 'recuperarPass',
      component: () => import('../screens/login/ScreenRecuperarPass.vue')
  },
  {
      path: '/login',
      name: 'login',
      component: () => import('../screens/login/ScreenLogin.vue')
  },
  {
      path: '/activarCliente',
      name: 'activarCliente',
      component: () => import('../screens/cliente/ActivarCliente.vue')
  },
  {
      path: '*', redirect: '/login'
  }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router

//con el comentario de abajo, no aparece el error 'no-unused-vars' con la siguiente linea de codigo
// eslint-disable-next-line no-unused-vars
router.afterEach((to,from) => {
  store.commit("pagina/setTitulo",to.meta.title);
});

router.beforeEach((to, from, next) => {
  const publicPages = ['/login','/activarCliente','/recuperarPass'];

  //logout
  if(to.path == '/logout'){
    userService.logout();
  }

  //reviso si usuario tiene acceso a pagina
  if(tieneAcceso(to) == false){
    return next(from.path);
  }

  // redirect to login page if not logged in and trying to access a restricted page
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');
  
  if (authRequired && !loggedIn) {
      return next('/login');
  }

  next();
});

//userService.getUsuarioInicio();

//socket
//si el usuario recarga la pantalla (reload), conecta el socket si el usuario esta logeado
if (store.state.usuario.id_usuario != null && (!Vue.prototype.$socket || Vue.prototype.$socket.connected == false)) { 
  Vue.use(new VueSocketIO({
    debug: true,
    connection: SocketIO(config.socketUrl,{
      query:{
        d: utils.je64({
          c: store.state.usuario.id_cliente,
          u: store.state.usuario.id_usuario
        })
      }
    }),
    vuex: {
      store,
      actionPrefix: 'socket_',
      mutationPrefix: 'socket_'
    },
  }))
}


function tieneAcceso(to){
  const accesoLibre = ['/login','/activarCliente','/home','/recuperarPass'];
  let to_name = "/"+to.name;
  let tiene_acceso = false;

  if(store.state.usuario.id_usuario != null){
    //reviso las rutas libres
    if(accesoLibre.includes(to_name)){
      return tiene_acceso = true;
    }

    if(store.state.usuario.menu == null){
      //reviso que tenga menu
      return tiene_acceso = false;
    }

    if(store.state.usuario.menu.find((element) => (element.href == to.path || element.href == to_name))){
      tiene_acceso = true;
    }

    if(tiene_acceso == false){
      console.log('sin acceso');
    }
    
    return tiene_acceso;
  }
}