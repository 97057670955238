import Vue from 'vue'
import VueSocketIO from 'vue-socket.io'
import { utils } from '@/helpers'
import SocketIO from 'socket.io-client';

import { api } from './api';
import { config } from './config';
import store from '../store/index.js';

var sha1 = require('js-sha1');

export const userService = {
  existeUsuarioUniqueId,
  recuperarPassUsuario,
  getUsuarioInicio,
  getUsuarioStorage,
  deleteUsuarioStorage,
  getUsuario,
  getUsuarios,
  getUsuarioHistorial,
  postUsuario,
  postUsuarioTrabajador,
  putUsuario,
  putUsuarioPass,
  getUsuarioInformacion,
  login,
  logout,
  getUsuarioSucursales,
  putUsuarioSucursal,
  putUsuarioTrabajador,
  getUsuariosTrabajador
};

function getUsuariosTrabajador(data){
  return api.post(
    api.tx.getUsuariosTrabajador, 
    data
  ).then(data => {
    return data;
  });
}

function putUsuarioTrabajador(data){
  return api.post(
    api.tx.putUsuarioTrabajador, 
    data
  ).then(data => {
    return data;
  });
}

function postUsuarioTrabajador(data){
  return api.post(
    api.tx.postUsuarioTrabajador, 
    data
  ).then(data => {
    return data;
  });
}

function existeUsuarioUniqueId(data){
  return api.post(
    api.tx.existeUsuarioUniqueId, 
    data
  ).then(data => {
    return data;
  });
}

function getUsuarioSucursales(data){
  return api.post(
    api.tx.getUsuarioSucursales, 
    data
  ).then(data => {
    return data;
  });
}

function putUsuarioSucursal(data){
  return api.post(
    api.tx.putUsuarioSucursal, 
    data
  ).then(data => {
    return data;
  });
}

function getUsuarioInformacion(data){
  return api.post(
    api.tx.getUsuarioInformacion, 
    data
  ).then(data => {
    return data;
  });
}

function getUsuarioHistorial(data){
  return api.post(
    api.tx.getUsuarioHistorial, 
    data
  ).then(data => {
    return data;
  });
}

function getUsuario(data){
  return api.post(
    api.tx.getUsuario, 
    data
  ).then(data => {
    return data;
  });
}

function recuperarPassUsuario(data){
  return api.post(
    api.tx.recuperarPassUsuario, 
    data
  ).then(data => {
    return data;
  });
}

function getUsuarios(data){
  return api.post(
    api.tx.getUsuarios, 
    data
  ).then(data => {
    return data;
  });
}

function postUsuario(data){
  return api.post(
    api.tx.postUsuario, 
    data
  ).then(data => {
    return data;
  });
}

function putUsuario(data){
  return api.post(
    api.tx.putUsuario, 
    data
  ).then(data => {
    return data;
  });
}

function putUsuarioPass(data){
  return api.post(
    api.tx.putUsuarioPass, 
    data
  ).then(data => {
    return data;
  });
}

function getUsuarioInicio(){
  let usuario = getUsuarioStorage();

  if(usuario == null){
    logout();
  }else{
    store.dispatch('usuario/setUsuario', usuario);
    setUsuarioStorage(usuario);

    userService.getUsuario({
      'id_cliente':usuario.id_cliente,
      'id_usuario':usuario.id_usuario,
      't':usuario.t
    }).then(respuesta => {
      if(respuesta.status == 'success'){
        store.dispatch('usuario/setUsuario', respuesta.data);
        setUsuarioStorage(respuesta.data);
      }else if(respuesta.status == 'warning'){
        logout();
      }   
    });
  }
}

function getUsuarioStorage(){
  let usuario = JSON.parse(localStorage.getItem('user'));

  return usuario;
}

function setUsuarioStorage(usuario){
  localStorage.setItem('user', JSON.stringify(usuario));
}

function deleteUsuarioStorage(){
  localStorage.removeItem('user');
}

function login(u, p) {
  p = sha1(p);

  return api.post(
    api.tx.login, {u,p}
  ).then(data => {
    if(data.status == "success"){
      setUsuarioStorage(data.data);
      store.commit('usuario/putUsuario', data.data);

      if (store.state.usuario.id_usuario != null && (!Vue.prototype.$socket || Vue.prototype.$socket.connected == false)) { 
        Vue.use(new VueSocketIO({
          debug: true,
          connection: SocketIO(config.socketUrl,{
            query:{
              d: utils.je64({
                c: store.state.usuario.id_cliente,
                u: store.state.usuario.id_usuario
              })
            }
          }),
          vuex: {
            store,
            actionPrefix: 'socket_',
            mutationPrefix: 'socket_'
          },
        }))
      }
    }
    return data;
  });
}

function logout() {
  store.commit("usuario/deleteUsuario");
  deleteUsuarioStorage();
  if(Vue.prototype.$socket){
    Vue.prototype.$socket.disconnect();
  }
}