import { api } from './api';

export const formularioService = {
    getFormulario,
    postFormulario,
    putFormulario,
    getCategoria,
    getChecklists,
    getChecklistBitacora,
    postChecklistBitacora,
    getCorreoChecklist,
    getChecklistArchivos,
    getChecklistsDetalle,
    getIndicadoresFormularios,
    getGraficoTortaFormulario,
    getGraficoHistorialFormulario,
    getFormularioReportes,
    deleteFormularioReporte,
    getFormularioPreguntas,
    postFormularioReporte,
    putFormularioReporte,
    getFormularioReporte,
    getFormularioReporteHistorial,
    getFormularioArbol,
    postFormularioCategoria,
    putFormularioCategoria,
    getFormularioCategoria,
    putFormularioCategoriaUsuario,
    putFormularioUsuario,
    getFormularioUsuarios,
    getFormularioCategoriaFormularios,
    getFormularioCategoriaUsuarios,
    putFormularioCategoriaFormulariosUsuario,
    getFormularioSeleccion,
    putFormularioSeleccion,
    postFormularioSeleccion,
    getFormularioSeleccionFormularios,
    getFormularioCumplimiento,
    getFormularioCumplimientoAgrupado
};

function getFormularioCumplimientoAgrupado(data) {
  return api.post(
    api.tx.getFormularioCumplimientoAgrupado, 
    data
  ).then(data => {
    return data;
  });
}
function getFormularioCumplimiento(data) {
  return api.post(
    api.tx.getFormularioCumplimiento, 
    data
  ).then(data => {
    return data;
  });
}

function getFormularioSeleccionFormularios(data) {
  return api.post(
    api.tx.getFormularioSeleccionFormularios, 
    data
  ).then(data => {
    return data;
  });
}

function postFormularioSeleccion(data) {
  return api.post(
    api.tx.postFormularioSeleccion, 
    data
  ).then(data => {
    return data;
  });
}

function putFormularioSeleccion(data) {
  return api.post(
    api.tx.putFormularioSeleccion, 
    data
  ).then(data => {
    return data;
  });
}

function getFormularioSeleccion(data) {
  return api.post(
    api.tx.getFormularioSeleccion, 
    data
  ).then(data => {
    return data;
  });
}

function putFormularioCategoriaFormulariosUsuario(data) {
  return api.post(
    api.tx.putFormularioCategoriaFormulariosUsuario, 
    data
  ).then(data => {
    return data;
  });
}

function putFormularioUsuario(data) {
  return api.post(
    api.tx.putFormularioUsuario, 
    data
  ).then(data => {
    return data;
  });
}

function getFormularioUsuarios(data) {
  return api.post(
    api.tx.getFormularioUsuarios, 
    data
  ).then(data => {
    return data;
  });
}

function getFormularioCategoriaFormularios(data) {
  return api.post(
    api.tx.getFormularioCategoriaFormularios, 
    data
  ).then(data => {
    return data;
  });
}
function putFormularioCategoriaUsuario(data) {
  return api.post(
    api.tx.putFormularioCategoriaUsuario, 
    data
  ).then(data => {
    return data;
  });
}

function getFormularioCategoriaUsuarios(data) {
  return api.post(
    api.tx.getFormularioCategoriaUsuarios, 
    data
  ).then(data => {
    return data;
  });
}

function getFormularioCategoria(data) {
  return api.post(
    api.tx.getFormularioCategoria, 
    data
  ).then(data => {
    return data;
  });
}

function postFormularioCategoria(data) {
  return api.post(
    api.tx.postFormularioCategoria, 
    data
  ).then(data => {
    return data;
  });
}

function putFormularioCategoria(data) {
  return api.post(
    api.tx.putFormularioCategoria, 
    data
  ).then(data => {
    return data;
  });
}

function getFormularioArbol(data) {
  return api.post(
    api.tx.getFormularioArbol, 
    data
  ).then(data => {
    return data;
  });
}

function getFormularioReporteHistorial(data) {
  return api.post(
    api.tx.getFormularioReporteHistorial, 
    data
  ).then(data => {
    return data;
  });
}

function getFormularioReporte(data) {
  return api.post(
    api.tx.getFormularioReporte, 
    data
  ).then(data => {
    return data;
  });
}

function putFormularioReporte(data) {
  return api.post(
    api.tx.putFormularioReporte, 
    data
  ).then(data => {
    return data;
  });
}

function postFormularioReporte(data) {
  return api.post(
    api.tx.postFormularioReporte, 
    data
  ).then(data => {
    return data;
  });
}

function getFormularioPreguntas(data) {
  return api.post(
    api.tx.getFormularioPreguntas, 
    data
  ).then(data => {
    return data;
  });
}

function deleteFormularioReporte(data) {
  return api.post(
    api.tx.deleteFormularioReporte, 
    data
  ).then(data => {
    return data;
  });
}

function getFormularioReportes(data) {
  return api.post(
    api.tx.getFormularioReportes, 
    data
  ).then(data => {
    return data;
  });
}

function getIndicadoresFormularios(data) {
  return api.post(
    api.tx.getIndicadoresFormularios, 
    data
  ).then(data => {
    return data;
  });
}

function getGraficoTortaFormulario(data) {
  return api.post(
    api.tx.getGraficoTortaFormulario, 
    data
  ).then(data => {
    return data;
  });
}

function getGraficoHistorialFormulario(data) {
  return api.post(
    api.tx.getGraficoHistorialFormulario, 
    data
  ).then(data => {
    return data;
  });
}

function getChecklistArchivos(data) {
  return api.post(
    api.tx.getChecklistArchivos, 
    data
  ).then(data => {
    return data;
  });
}

function getCorreoChecklist(data) {
  return api.post(
    api.tx.getCorreoChecklist, 
    data
  ).then(data => {
    return data;
  });
}

function postChecklistBitacora(data) {
  return api.post(
    api.tx.postChecklistBitacora, 
    data
  ).then(data => {
    return data;
  });
}

function getChecklistBitacora(data) {
  return api.post(
    api.tx.getChecklistBitacora, 
    data
  ).then(data => {
    return data;
  });
}

function getChecklists(data) {
  return api.post(
    api.tx.getChecklists, 
    data
  ).then(data => {
    return data;
  });
}

function getChecklistsDetalle(data) {
  return api.post(
    api.tx.getChecklistsDetalle, 
    data
  ).then(data => {
    return data;
  });
}

function postFormulario(data) {
  return api.post(
    api.tx.postFormulario, 
    data
  ).then(data => {
    return data;
  });
}

function putFormulario(data) {
  return api.post(
    api.tx.putFormulario, 
    data
  ).then(data => {
    return data;
  });
}

function getFormulario(data) {
  return api.post(
    api.tx.getFormulario, 
    data
  ).then(data => {
    return data;
  });
}

function getCategoria(data) {
  return api.post(
    api.tx.getCategoria, 
    data
  ).then(data => {
    return data;
  });
}