import { api } from './api';

export const prevnService = {
  getMenu,
};

function getMenu(data) {
  return api.post(
    api.tx.getMenu, 
    data
  ).then(data => {
    return data;
  });
}