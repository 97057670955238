import AES from 'crypto-js/aes'
import encHex from 'crypto-js/enc-hex'

import VueSimpleAlert from "vue-simple-alert"
import {userService} from "@/services"
import store from '../store/index.js'

import dateformat, { masks } from "dateformat";
import { config } from "../services/config";

//reemplazo un elemento (searchValue) de arr, con todos los elementos de newArray
function replaceValueWithArray(arr, searchValue, newArray) {
  let newArr = [...arr];
  for (let i = 0; i < newArr.length; i++) {
    if (newArr[i] === searchValue) {
      newArr.splice(i, 1, ...newArray);
      break;
    }
  }
  return newArr;
}

function encrypt(data){
  if(config.env == 'production'){
    let key = encHex.parse("4a7e123d71b24e70616b42aa228fbb6c");
    let iv =  encHex.parse("f2a183b31d6c4278a31e1f9b73cb9d1a");

    data = AES.encrypt(data, key, {iv:iv}).toString();
  }

  return data;
}

function coloresGraficos(){
  return [
    "rgb(228, 26, 28)",  "rgb(55, 126, 184)",  "rgb(77, 175, 74)",  "rgb(152, 78, 163)",  "rgb(255, 127, 0)",  
    "rgb(255, 255, 51)",  "rgb(166, 86, 40)",  "rgb(247, 129, 191)",  "rgb(153, 153, 153)",  "rgb(242, 148, 0)",  
    "rgb(204, 204, 0)",  "rgb(178, 223, 138)",  "rgb(31, 120, 180)",  "rgb(106, 61, 154)",  "rgb(255, 187, 120)",  
    "rgb(166, 206, 227)",  "rgb(178, 223, 138)",  "rgb(251, 154, 153)",  "rgb(253, 191, 111)",  "rgb(202, 178, 214)",  
    "rgb(61, 61, 61)",  "rgb(240, 163, 255)",  "rgb(0, 153, 143)",  "rgb(255, 194, 10)",  "rgb(41, 128, 185)",  
    "rgb(158, 94, 243)",  "rgb(255, 77, 148)",  "rgb(255, 242, 0)",  "rgb(239, 71, 111)",  "rgb(0, 209, 178)",  
    "rgb(0, 128, 128)",  "rgb(178, 102, 255)",  "rgb(255, 0, 0)",  "rgb(255, 255, 153)",  "rgb(0, 0, 255)",  
    "rgb(0, 255, 0)",  "rgb(128, 0, 128)",  "rgb(255, 128, 0)",  "rgb(128, 128, 128)",  "rgb(0, 0, 0)"];
}

function fileToBase64(file){ 
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

function mapAtributo(arrayObject, atributo){
  let array = arrayObject.map(function(element) {
    return element[atributo];
  });

  return array;
}

function DateToFecha(date, formato){
  masks.fecha = formato;
  return dateformat(date, "fecha");
}

function fechaToDate(string){
  let partes = string.split("-");
  return new Date(partes[0],partes[1]-1,partes[2]);
}

//lo paso a json(string) y luego a base64 encode
//objeto => string
function je64(data){
  var Buffer = require('buffer/').Buffer;
  return Buffer.from(JSON.stringify(data)).toString('base64');
}

//lo paso de bse64 a json(string), luego a objeto
//string => objeto
function jd64(data){
  var Buffer = require('buffer/').Buffer
  return JSON.parse(Buffer.from(data, 'base64').toString());
}

//b64 decode para tildes y caracteres extraños
function b64DecodeUnicode(str) {
  return decodeURIComponent(atob(str).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
}

function txtBtnCargar(estado){
  if(estado == 'A' || estado == 'R' || estado == 'V'){
    return "Reemplazar";
  }else{
    return "Buscar";
  }
}

function alerta(data){
  if(data.status == 'danger'){
    data.status = 'error';
  }
  VueSimpleAlert.alert(data.msg,'',data.status);
}

function inJsonArray(needle, atributo, json_array){
  let resultado = false;

  try {
    json_array.forEach(function(element){
      if(element[atributo] == needle){
        resultado = true;
      }
    });
  }catch(e){
    return false;
  }
  return resultado;
}

function regla3(total, no_total){
  if(total == 0){
    return 0;
  }
  return Math.round((no_total*100/total)*10)/10;
}

function pintaColor(valor){
  if(valor < 41){
    return "red-bg text-light";
  }
  if(valor < 81){
    return "yellow-bg text-light";
  }
  if(valor > 0){
    return "navy-bg text-light";
  }
  return "";
}

function tienePermiso(){
  if(store.state.usuario.permiso == "1"){
    return true;
  }
  return false;
}

function mostrarFiltroEmpresa(){
  if(userService.getUser().id_empresa == 0){
    return true;
  }
  return false;
}

function formularioDescTipo(id_tipo){
  if(id_tipo == '1'){
    return 'Selección simple';
  }else if(id_tipo == '2'){
    return 'Selección multiple';
  }else if(id_tipo == '3'){
    return 'Texto';
  }else if(id_tipo == '4'){
    return 'Numero';
  }else if(id_tipo == '5'){
    return 'Fecha';
  }else if(id_tipo == '6'){
    return 'Hora';
  }else if(id_tipo == '7'){
    return 'Cabecera';
  }else if(id_tipo == '8'){
    return 'Correlativo';
  }else if(id_tipo == '9'){
    return 'Firma';
  }else if(id_tipo == '10'){
    return 'Fecha hora';
  }else if(id_tipo == '11'){
    return 'Foto';
  }else if(id_tipo == '12'){
    return 'Fecha plazo';
  }else if(id_tipo == '13'){
    return 'QR';
  }else if(id_tipo == '14'){
    return 'Cumplimiento';
  }else if(id_tipo == '15'){
    return 'Rut';
  }else if(id_tipo == '16'){
    return 'Seleccion simple con lista de correos';
  }else{
    return id_tipo;
  }
}

function formularioIconTipo(id_tipo){
  if(id_tipo == '1'){
    return 'list';
  }else if(id_tipo == '2'){
    return 'list-task';
  }else if(id_tipo == '3'){
    return 'textarea-t';
  }else if(id_tipo == '4'){
    return 'calculator';
  }else if(id_tipo == '5'){
    return 'calendar3';
  }else if(id_tipo == '6'){
    return 'clock';
  }else if(id_tipo == '7'){
    return 'card-heading';
  }else if(id_tipo == '8'){
    return 'list-ol';
  }else if(id_tipo == '9'){
    return 'pencil';
  }else if(id_tipo == '10'){
    return 'calendar-minus';
  }else if(id_tipo == '11'){
    return 'camera-fill';
  }else if(id_tipo == '12'){
    return 'calendar2-check';
  }else if(id_tipo == '13'){
    return 'grid';
  }else if(id_tipo == '14'){
    return 'card-checklist';
  }else if(id_tipo == '15'){
    return 'person-fill';
  }else if(id_tipo == '16'){
    return 'envelope-fill';
  }else{
    return id_tipo;
  }
}

export const utils = {
  je64,
  jd64,
  pintaColor,
  alerta,
  mostrarFiltroEmpresa,
  tienePermiso,
  regla3,
  txtBtnCargar,
  inJsonArray,
  DateToFecha,
  mapAtributo,
  fileToBase64,
  fechaToDate,
  formularioDescTipo,
  formularioIconTipo,
  b64DecodeUnicode,
  coloresGraficos,
  encrypt,
  replaceValueWithArray
}
