import { api } from './api';

export const sistemaService = {
  getSistemaConfiguracion,
  putSistemaConfiguracion
};

function getSistemaConfiguracion(data){
  return api.post(
    api.tx.getSistemaConfiguracion, 
    data
  ).then(data => {
    return data;
  });
}

function putSistemaConfiguracion(data){
  return api.post(
    api.tx.putSistemaConfiguracion, 
    data
  ).then(data => {
    return data;
  });
}