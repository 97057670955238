import { api } from './api';

export const logService = {
    getLog
};

function getLog(data){
  return api.post(
    api.tx.getLog, 
    data
  ).then(data => {
    return data;
  });
}