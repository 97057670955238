import { api } from './api';

export const estadisticaService = {
  getEstadistica,
  putEstadistica,
  getEstadisticaReporte,
  getEstadisticaTasas,
  getEstadisticaTasasAcumuladas
};

function getEstadistica(data){
  return api.post(
    api.tx.getEstadistica, 
    data
  ).then(data => {
    return data;
  });
}

function putEstadistica(data){
  return api.post(
    api.tx.putEstadistica, 
    data
  ).then(data => {
    return data;
  });
}

function getEstadisticaReporte(data){
  return api.post(
    api.tx.getEstadisticaReporte, 
    data
  ).then(data => {
    return data;
  });
}

function getEstadisticaTasas(data){
  return api.post(
    api.tx.getEstadisticaTasas, 
    data
  ).then(data => {
    return data;
  });
}

function getEstadisticaTasasAcumuladas(data){
  return api.post(
    api.tx.getEstadisticaTasasAcumuladas, 
    data
  ).then(data => {
    return data;
  });
}