import { api } from './api';

export const widgetClienteService = {
  getWidgetClienteRvcIpaKpi,
  getWidgetClienteRvcIpa,
  postWidgetClienteRvcIpa,
  getWidgetClienteCmpcDiasPerdidos,
  postWidgetClienteCmpcDiasPerdidos,
  getWidgetClienteCmpcDiasPerdidosTabla
};

function getWidgetClienteCmpcDiasPerdidos(data) {
  return api.post(
    api.tx.getWidgetClienteCmpcDiasPerdidos, 
    data
  ).then(data => {
    return data;
  });
}

function postWidgetClienteCmpcDiasPerdidos(data) {
  return api.post(
    api.tx.postWidgetClienteCmpcDiasPerdidos, 
    data
  ).then(data => {
    return data;
  });
}

function getWidgetClienteCmpcDiasPerdidosTabla(data) {
  return api.post(
    api.tx.getWidgetClienteCmpcDiasPerdidosTabla, 
    data
  ).then(data => {
    return data;
  });
}

function getWidgetClienteRvcIpaKpi(data) {
  return api.post(
    api.tx.getWidgetClienteRvcIpaKpi, 
    data
  ).then(data => {
    return data;
  });
}

function getWidgetClienteRvcIpa(data) {
  return api.post(
    api.tx.getWidgetClienteRvcIpa, 
    data
  ).then(data => {
    return data;
  });
}

function postWidgetClienteRvcIpa(data) {
  return api.post(
    api.tx.postWidgetClienteRvcIpa, 
    data
  ).then(data => {
    return data;
  });
}