import { utils } from '@/helpers';
import { userService } from './user.services.js';
import { config } from './config';
import store from '../store/index.js';

const tx = {
  //usuario
  'recuperarPassUsuario':1,
  'existeUsuarioUniqueId':2,
  'login':14,
  'getUsuario':13,
  'getUsuarios':7,
  'putUsuario':8,
  'postUsuario':9,
  'putUsuarioPass':12,
  'getUsuarioInformacion':15,
  'getUsuarioSucursales':16,
  'postUsuarioHistorial':17,
  'getUsuarioHistorial':18,
  'postUsuarioTrabajador':19,
  'putUsuarioTrabajador':20,
  'getUsuariosTrabajador':21,

  //inspeccion
  'postInspeccionBitacora':104,
  'getInspeccionEjecutores':106,
  'agregarevidenciafoto':107,
  'getInspeccionIndicadoresSucursal':113,
  'getInspeccionGraficoTorta':114,
  'getInspeccionGraficoHistorial':115,
  'getCorreoInspeccion':118,
  'getInspeccionTipoInspeccionArbol':119,
  'postInspeccionTipoInspeccion':120,
  'putInspeccionTipoInspeccion':121,
  'getInspeccionMotivoReasignacion':122,
  'postInspeccionMotivoReasignacion':123,
  'putInspeccionMotivoReasignacion':124,
  'getInspeccionMotivoCancelacion':125,
  'postInspeccionMotivoCancelacion':126,
  'putInspeccionMotivoCancelacion':127,
  'getInspecciones':128,
  'putInspeccion':129,
  'getInspeccionKpi':130,
  'getInspeccionIndicadoresTipo':131,
  'getInspeccionHistoricoEjecutores':132,
  'agregarevidenciaarchivo':133,
  'getInspeccionWidgetInspectorEjecutor':134,
  'getInspeccionWidgetPorTipo':135,
  'getInspeccionWidgetPorSubTipo':136,
  'getInspeccionBitacora':208,

  //documentos
  'getHtmlDocumentoEnviado':300,
  'postDocumentoEnviado':301,
  'putDocumentoEnviado':302,
  'getDocumentos':304,
  'getDocumento':305,
  'putDocumento':306,
  'postDocumento':307,
  'getDocumentoEnviados':308,

  //formularios
  'postChecklistBitacora':511,
  'getChecklistBitacora':516,
  'getGraficoTortaFormulario':521,
  'getGraficoHistorialFormulario':522,
  'getCorreoChecklist':523,
  'getChecklists':525,
  'getChecklistArchivos':526,
  'getChecklistsDetalle':527,
  'getFormularioReportes':528,
  'deleteFormularioReporte':529,
  'postFormularioReporte':531,
  'putFormularioReporte':532,
  'getFormularioReporte':533,
  'getFormularioReporteHistorial':534,
  'getFormularioArbol':535,
  'postFormularioCategoria':536,
  'putFormularioCategoria':537,
  'getFormularioCategoria':538,
  'getFormularioCategoriaUsuarios':539,
  'getFormularioUsuarios':540,
  'getFormularioCategoriaFormularios':541,
  'putFormularioCategoriaUsuario':542,
  'putFormularioUsuario':543,
  'putFormularioCategoriaFormulariosUsuario':544,
  'getFormulario':545,
  'postFormulario':546,
  'putFormulario':547,
  'getFormularioSeleccion':548,
  'putFormularioSeleccion':549,
  'postFormularioSeleccion':550,
  'getFormularioSeleccionFormularios':551,
  'getFormularioPreguntas':552,
  'getIndicadoresFormularios':553,
  'getFormularioCumplimiento':554,
  'getFormularioCumplimientoAgrupado':555,
  
  //cliente
  'postCliente':3000,
  'activarCliente':3001,
  'getCliente':3003,
  'getClienteMaximosUsuarios':3004,
  'putCliente':3005,
  'putClienteConfiguracion':3006,
  'getClienteConfiguracion':3007,
  'getClienteLogo':3008,

  //menu
  'getMenu':5000,
  'getMenuFuncionalidades':5001,
  'getMenuFuncionalidadPerfil':5002,
  'postMenuFuncionalidad':5003,
  'putMenuFuncionalidadPerfil':5004,
  'putMenuFuncionalidad':5005,

  //tareas
  'getTareas':913,
  'getTarea':901,
  'putTarea':902,
  'postTareaBitacora':903,
  'getTareaBitacora':904,
  'getTareaIndicadores':908,
  'getCorreoTarea':910,
  'getGraficoTortaTarea':911,
  'getGraficoHistorialTarea':912,
  'getTareaEjecutores':914,

  //programa
  'getProgramas':1604,
  'getProgramaActividades':1605,
  'getProgramaUsuarios':1606,
  'getProgramaChecklist':1607,
  'getProgramaReporteDetallado':1608,
  'getProgramaReporteKpiUsuarios':1609,
  'postPrograma':1610,
  'putPrograma':1611,
  'postProgramaActividad':1612,
  'putProgramaActividad':1613,
  'putProgramaUsuario':1614,
  'deleteProgramaActividad':1615,
  'getProgramaUsuariosAsociados':1616,
  'getProgramaTimeline':1617,
  'getProgramaIndicadores':1618,
  'getProgramaTimelineCalendarizacion':1619,
  'postProgramaCalendarizacion':1620,
  'putProgramaCalendarizacion':1621,
  'deleteProgramaCalendarizacion':1622,
  'copyProgramaCalendarizacion':1623,
  'deleteProgramaCalendarizacionMasivo':1624,
  'getDeleteProgramaCalendarizacionMasivo':1625,
  'getCopyProgramaCalendarizacion':1626,
  'getProgramaWidgetTablaAvance':1627,
  'getProgramaWidgetTablaAvanceFecha':1628,
  'getProgramaWidgetBarraAvanceFecha':1629,
  'getProgramaWidgetBarraFechaUsuarios':1630,

  //sucursal
  'getSucursal':6000,
  'postSucursal':6001,
  'putSucursal':6002,
  'getSucursalUsuarios':6003,
  'putSucursalUsuario':6004,
  'getSucursalAreas':6005,
  'postSucursalArea':6006,
  'putSucursalArea':6007,
  'getSucursalAreaUsuarios':6008,
  'putSucursalAreaUsuario':6009,

  //estadistica
  'getEstadistica':7000,
	'putEstadistica':7001,
	'getEstadisticaReporte':7002,
	'getEstadisticaTasas':7003,
  'getEstadisticaTasasAcumuladas':7004,
  
  //lista_correo
  'getListasCorreo':8000,
	'postListaCorreo':8001,
	'putListaCorreo':8002,
  'getListaCorreo':8003,
  
  //organizacion
  'getOrganizacionUsuarios':9000,
  'postOrganizacion':9001,
  'putOrganizacion':9002,
  'getOrganizacionArbol':9003,
  'putOrganizacionUsuario':9004,
  'addTodoOrganizacionUsuario':9005,
  'deleteTodoOrganizacionUsuario':9006,

  //widget
  'getWidgetInicio':10000,
  'postWidgetInicio':10001,
  'getWidgets':10002,

  //sistema
  'getSistemaConfiguracion':11000,
  'putSistemaConfiguracion':11001,

  //widget_cliente
  'getWidgetClienteRvcIpaKpi':12000,
  'getWidgetClienteRvcIpa':12001,
  'postWidgetClienteRvcIpa':12002,
  'getWidgetClienteCmpcDiasPerdidos':12003,
  'postWidgetClienteCmpcDiasPerdidos':12004,
  'getWidgetClienteCmpcDiasPerdidosTabla':12005,

  //log
  'getLog':13000
}

function fileDownload(url, filename){
  fetch(url).then(function(response) {
    if (!response.ok) {
      throw new Error("HTTP error, status = " + response.status);
    }
    return response.arrayBuffer();
  })
  .then(response => {
    const url = window.URL.createObjectURL(new Blob([response]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', filename) //or any other extension
    document.body.appendChild(link)
    link.click()
  })
}

function requestOptions(codigo, data) {
  let token = (codigo == tx.login || codigo == tx.recuperarPassUsuario || codigo == tx.existeUsuarioUniqueId || codigo == tx.putUsuarioPass) ? null : userService.getUsuarioStorage().t

  let json = JSON.stringify({
    'c':codigo,
    'd':data
  });

  return {
    method: 'POST',
    headers: { 
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
        'CF-Access-Token': `Bearer ${token}`
    },
    body: utils.encrypt(json)
  }
}

function post(codigo, data){
  //por si se necesita enviar el id_cliente
  if(data.id_cliente == null){
    data.id_cliente = store.state.usuario.id_cliente;
  }
  if(store){
    data.id_usuario_responsable = store.state.usuario.id_usuario;
  }

  let options = requestOptions(
    codigo,
    data
  );
  
  return fetch(`${config.apiUrl}`, options)
    .then(handleResponse)
    .catch(error => {
      return {
        msg:error,
        status:'danger'
      };
    }).then(data => {
      return data;
    });
}

function handleResponse(response) {
  let data;
  return response.text().then(text => {
      try {
        data = text && JSON.parse(text);

        if (!response.ok) {
            if (response.status === 401) {
              // auto logout if 401 response returned from api
              userService.logout();
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
      } catch (error){
        if(config.isDev){
          return {msg:error,status:'danger'};
        }else{
          return {msg:'Error de conexion',status:'danger'};
        }
      }
      return data;
  });
}

export const api = {
  fileDownload,
  requestOptions,
  tx,
  post,
  handleResponse
}
