<template>
  <div>
    <div v-if="!loading">
      <b-list-group v-for="(item,index) in rows" :key="index">
        <b-col cols="12" style="padding-left:0; padding-right:0" v-if="padre == 0 ||  isOpen[item.id_padre]">
          <b-list-group-item  :style="marginLeft(padre)" class="flex-column align-items-start mb-1 bg-light">
            <b-row>
              <b-col lg="8">
                <!--<i v-if="tieneHijos(item.data)" :class="toggleOffCat[item[id]] ? 'fas fa-plus handle icono' :'fas fa-minus handle icono'" v-on:click="toggleOffCat[item[id]] ? ToggleOn(item[id]) : ToggleOff(item[id])"></>i-->
                <i v-if="tieneHijos(item.data)" :class="isOpen[item[id]] ? 'fas fa-minus handle icono' :'fas fa-plus handle icono'" style="cursor:pointer" v-on:click="toggle(item)"> </i>
                {{item[nombre]}}
                <b-badge style="margin-left: 5px" :variant="HabilitadoColor(item.habilitado)">{{Habilitado(item.habilitado)}}</b-badge>
              </b-col>
              <b-col lg="4" class="d-flex flex-row-reverse">
                <slot
                    name="list-row-cat"
                    :row="item"
                    :index="index">
                </slot>
              </b-col>
            </b-row>
          </b-list-group-item>
          <ListGroup
            :isOpenInitial=isOpen
            :padre="padre+1"
            :rows="item.data"
            :id="'id_elemento'"
            :nombre="'descripcion'"
            @agregar=openModalTipoInspeccionAgregar
            @editar=openModalTipoInspeccionEditar>
            <template slot="list-row-cat" slot-scope="props">
              <span v-if="utils.tienePermiso()">
                <a href="javascript:void(0)" v-on:click="$emit('agregar',props.row)">Agregar subtipo inspección</a>
                |
                <a href="javascript:void(0)" v-on:click="$emit('editar',props.row)">Editar</a>
              </span>
            </template>
          </ListGroup>
        </b-col>
      </b-list-group>
    </div>
    <b-skeleton-table v-else :columns="2"></b-skeleton-table>

  </div>
</template>

<script>

import { utils } from '@/helpers';

export default {
  name: "ListGroup",
  props: {
    loading:{},
    isOpenInitial:{default:null},
    padre: {}, 
    rows :{},
    nombre:{},
    id:{}
  },
  watch: {
    loading(){
      //seteo valores iniciales de padres
      this.rows.forEach(element => {
        this.$set(this.isOpen, element.id_elemento, true);
      });
    }
  },
  mounted() {
    //seteo valores iniciales de hijos
    this.rows.forEach(element => {
      this.$set(this.isOpen, element.id_elemento, true);
    });
  },
  data() {
    return {
      utils,
      toggleOffCat:[],
      isOpen:this.isOpenInitial == null ? []:this.isOpenInitial
    }
  },
  methods:{
    openModalTipoInspeccionAgregar(row){
      this.$emit('agregar',row);
    },
    openModalTipoInspeccionEditar(row){
      this.$emit('editar',row);
    },
    marginLeft(padre){
      return 'margin-left:'+ 30*padre + 'px';
    },
    toggle(row){
      let id = row[this.id];
      this.$set(this.isOpen, id, this.isOpen[id] = !this.isOpen[id] );

      row.data.forEach(element => {
        this.toggle(element);
      });
    },
    ToggleOff(id_padre){
      this.$set(this.toggleOffCat, id_padre, true)
    },
    ToggleOn(id_padre){
      this.$set(this.toggleOffCat, id_padre, false)
    },
    Habilitado(habilitado){
      if(habilitado == 'S'){
        return 'Habilitado'
      }else{
        return 'Deshabilitado'
      }
    },
    HabilitadoColor(habilitado){
      if(habilitado == 'S'){
        return 'info'
      }else{
        return 'danger'
      }
    },
    tieneHijos(data){
      return data.length != 0
    }

  }
}
</script>

<style scoped>
  .icono {
    margin-right: 20px;
  }

  .handle {
    float: left;
    padding-top: 4px;
    padding-bottom: 4px;
  }

  .list-group-item:hover {
    font-weight: bold;
  }
</style>