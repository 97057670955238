import { api } from './api';

export const clienteService = {
    getCliente,
    postCliente,
    putCliente,
    activarCliente,
    getClienteMaximosUsuarios,
    getClienteConfiguracion,
    putClienteConfiguracion,
    getClienteLogo
};

function getClienteLogo(data){
  return api.post(
    api.tx.getClienteLogo, 
    data
  ).then(data => {
    return data;
  });
}

function getClienteConfiguracion(data){
  return api.post(
    api.tx.getClienteConfiguracion, 
    data
  ).then(data => {
    return data;
  });
}

function putClienteConfiguracion(data){
  return api.post(
    api.tx.putClienteConfiguracion, 
    data
  ).then(data => {
    return data;
  });
}

function getClienteMaximosUsuarios(data){
  return api.post(
    api.tx.getClienteMaximosUsuarios, 
    data
  ).then(data => {
    return data;
  });
}

function postCliente(data) {
  return api.post(
    api.tx.postCliente, 
    data
  ).then(data => {
    return data;
  });
}

function putCliente(data) {
  return api.post(
    api.tx.putCliente, 
    data
  ).then(data => {
    return data;
  });
}

function getCliente(data) {
  return api.post(
    api.tx.getCliente, 
    data
  ).then(data => {
    return data;
  });
}

function activarCliente(data) {
    return api.post(
        api.tx.activarCliente,
        data
    ).then(data => {
      return data;
    });
}