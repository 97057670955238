<template>
  <b-navbar toggleable="lg" style="background-color:white;">
    <h2 style="color:#f3a100">{{ this.$store.state.pagina.titulo }} </h2>
      <b-collapse id="nav-collapse" is-nav></b-collapse>
    <h4 style="color:black">{{ this.$store.state.usuario.nombre_usuario }} - {{ this.$store.state.usuario.nombre_cliente}}</h4>
  </b-navbar>
</template>
<script>

export default {
  data() {
    return {
      titulo:document.title
    };
  },
};
</script>

<style scoped>
  h2 {
    font-weight:400;
    margin:0;
    margin-left:15px;
    margin-top:5px; 
    color: #424242;
    font-family: Roboto,sans-serif;
    text-shadow: rgb(0 0 0 / 15%) 0 0 1px;
    font-size:22px
  }

  h4 {
    font-weight: 200;
    margin:0;
    margin-left:15px;
    margin-right: 20px;
    margin-top:5px; 
    font-family: Roboto,sans-serif;
    font-size:14px
  }
  
</style>
