import { api } from './api';

export const documentoService = {
  getDocumentos,
  getDocumento,
  putDocumento,
  postDocumento,
  getDocumentoEnviados,
  getHtmlDocumentoEnviado,
  postDocumentoEnviado,
  putDocumentoEnviado
};

function putDocumentoEnviado(data){
  return api.post(
    api.tx.putDocumentoEnviado, 
    data
  ).then(data => {
    return data;
  });
}

function postDocumentoEnviado(data){
  return api.post(
    api.tx.postDocumentoEnviado, 
    data
  ).then(data => {
    return data;
  });
}

function getHtmlDocumentoEnviado(data){
  return api.post(
    api.tx.getHtmlDocumentoEnviado, 
    data
  ).then(data => {
    return data;
  });
}

function getDocumentoEnviados(data){
  return api.post(
    api.tx.getDocumentoEnviados, 
    data
  ).then(data => {
    return data;
  });
}

function getDocumentos(data){
  return api.post(
    api.tx.getDocumentos, 
    data
  ).then(data => {
    return data;
  });
}

function getDocumento(data){
  return api.post(
    api.tx.getDocumento, 
    data
  ).then(data => {
    return data;
  });
}

function putDocumento(data){
  return api.post(
    api.tx.putDocumento, 
    data
  ).then(data => {
    return data;
  });
}

function postDocumento(data){
  return api.post(
    api.tx.postDocumento, 
    data
  ).then(data => {
    return data;
  });
}