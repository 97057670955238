<template>
  <div>
    <div style="padding: 200px 20px 20px 20px; margin: 0 auto; max-width: 400px">
      <div class="row">
        <div class="col-md-12">
          <div class="ibox-content" >
            <div>
                <img width="300" style="display: block;margin-left: auto;margin-right: auto;" src="../assets/logo.png">
            </div>
            <h2 style="margin-top:50px">Sesión expirada</h2>
            <b-button v-on:click="salir" variant="primary" style="margin-top: 20px; width:100%">Salir</b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  methods : {
    salir : function(){
      this.$router.go("/login");
    }
  }
}

</script>

<style scoped>
  h2 {
    color:black;
    margin-top:50px;
    text-align: center;
  }
  .ibox-content {
    background-color: #e7eaec
  }
</style>
