const perfil = {
  'admin': 0,
  'admin_cliente':6,
  'admin_sucursal':12,
  'admin_categoria':1,
  'inspector':7,
  'ejecutor':11,
  'trabajador':13
}

const objeto_tipo = {
  'seleccion_simple': '1',
  'seleccion_multiple': '2',
  'texto': '3',
  'numero': '4',
  'fecha': '5',
  'hora': '6',
  'cabecera': '7',
  'correlativo': '8',
  'firma': '9',
  'fecha_hora': '10',
  'foto': '11',
  'fecha_plazo': '12',
  'qr': '13',
  'cumplimiento': '14',
  'rut': '15',
  'lista_correo': '16'
}

function isInArray(array1, array2){
  for(let i=0;i<array2.length;i++){
    if(array1.includes(array2[i]) ){
      return true;
    }
  }
  return false;
}

function validarRut(rut) {
  rut = rut.replace("-","").replace(".","");
    
  if (rut.toString().trim() != '') {
      var caracteres = new Array();
      var serie = new Array(2, 3, 4, 5, 6, 7);
      var dig = rut.toString().substr(rut.toString().length - 1, 1);
      rut = rut.toString().substr(0, rut.toString().length - 1);

      for (var i = 0; i < rut.length; i++) {
          caracteres[i] = parseInt(rut.charAt((rut.length - (i + 1))));
      }

      var sumatoria = 0;
      var k = 0;
      var resto = 0;

      for (var j = 0; j < caracteres.length; j++) {
          if (k == 6) {
              k = 0;
          }
          sumatoria += parseInt(caracteres[j]) * parseInt(serie[k]);
          k++;
      }

      resto = sumatoria % 11;
      let dv = 11 - resto;

      if (dv == 10) {
          dv = "K";
      }else if (dv == 11) {
          dv = 0;
      }

      if (dv.toString().trim().toUpperCase() == dig.toString().trim().toUpperCase())
          return true;
      else
          return false;
  }else {
      return false;
  }
}

function validarCorreo(correo) {
 if (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/.test(correo)){
    return true;
  }
  return false;
}

function searchArraySelect(nameKey, myArray){
  for (var i=0; i < myArray.length; i++) {
      if (myArray[i].v === nameKey) {
          return myArray[i];
      }
  }
}

function searchArraySelect2(nameKey, myArray, key){
  for (var i=0; i < myArray.length; i++) {
      if (myArray[i][key] === nameKey) {
          return myArray[i];
      }
  }
}

function getFileExtension(filename){
  let re = /(?:\.([^.]+))?$/;
  return re.exec(filename)[1].toLowerCase();
}

export const globales = {
  perfil,
  objeto_tipo,
  isInArray,
  validarRut,
  validarCorreo,
  searchArraySelect,
  searchArraySelect2,
  getFileExtension
}
