import { api } from './api';

export const organizacionService = {
  getOrganizacionArbol,
  postOrganizacion,
  putOrganizacion,
  getOrganizacionUsuarios,
  putOrganizacionUsuario,
  addTodoOrganizacionUsuario,
  deleteTodoOrganizacionUsuario,
};

function addTodoOrganizacionUsuario(data) {
  return api.post(
    api.tx.addTodoOrganizacionUsuario, 
    data
  ).then(data => {
    return data;
  });
}

function deleteTodoOrganizacionUsuario(data) {
  return api.post(
    api.tx.deleteTodoOrganizacionUsuario, 
    data
  ).then(data => {
    return data;
  });
}

function putOrganizacionUsuario(data) {
  return api.post(
    api.tx.putOrganizacionUsuario, 
    data
  ).then(data => {
    return data;
  });
}

function getOrganizacionUsuarios(data) {
  return api.post(
    api.tx.getOrganizacionUsuarios, 
    data
  ).then(data => {
    return data;
  });
}

function getOrganizacionArbol(data) {
  return api.post(
    api.tx.getOrganizacionArbol, 
    data
  ).then(data => {
    return data;
  });
}

function postOrganizacion(data) {
  return api.post(
    api.tx.postOrganizacion, 
    data
  ).then(data => {
    return data;
  });
}

function putOrganizacion(data) {
  return api.post(
    api.tx.putOrganizacion, 
    data
  ).then(data => {
    return data;
  });
}